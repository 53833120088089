import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Button, Stack, Box, Grid } from "@mui/material"
import GitHubIcon from "@mui/icons-material/GitHub"
import VisibilityIcon from "@mui/icons-material/Visibility"

const ProjectTemplate = ({ data }) => {
  const { frontmatter, body } = data.mdx
  const { title, desc, github, projectSite, tags, image } = frontmatter

  return (
    <Layout>
      <Seo title={title} description={desc} />
      <section className="blog-template">
        <div className="section-center">
          <h1>{title}</h1>
          <Box
            sx={{
              display: "grid",
              justifyItems: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {github && (
                <a href={github} target="_blank" rel="noreferrer">
                  <Button variant="outlined" startIcon={<GitHubIcon />}>
                    Github
                  </Button>
                </a>
              )}
              {projectSite && (
                <a href={projectSite} target="_blank" rel="noreferrer">
                  <Button
                    variant="outlined"
                    color="info"
                    startIcon={<VisibilityIcon />}
                  >
                    View live
                  </Button>
                </a>
              )}
            </Stack>
          </Box>
          <Box
            component="div"
            sx={{
              pt: 3,
              pb: 5,
              width: {
                xs: "100%",
                sm: "80%",
                md: "65%",
              },
              mx: "auto",
            }}
          >
            <GatsbyImage image={getImage(image)} alt={title} />
          </Box>
          <MDXRenderer>{body}</MDXRenderer>
          <div className="btn-stack">
            {tags.map((item, index) => {
              return <span key={index}>{item}</span>
            })}
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Link to="/projects">
              <Button variant="contained">Back to all projects</Button>
            </Link>
          </Box>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query getPost($slug: String) {
    mdx(slug: { eq: $slug }) {
      id
      slug
      body
      frontmatter {
        date
        desc
        platform
        projectSite
        github
        tags
        title
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

export default ProjectTemplate
